/* * * * * * * * * * * * */
/* Initialize functions  */
/* * * * * * * * * * * * */

  initialize = {

    docReady: function() {
      fx_menu.make();
      fx_inputs.validate();
      fx_components.datepicker();

      // Cookie

      function cookie() {
        var $cookiePrivacy = $('cookie');
        var $cookiePrivacyClose = $('cookie .close');
        var $cookiePrivacyAccept= $('cookie .accept');

        // document.cookie = "cookie_accept=1; expires=Thu, 18 Dec 2013 12:00:00 UTC;"; // Reset cookie

        var dCookie = document.cookie;
            dCookie = dCookie.replace(/ /g, '');
            dCookie = dCookie.split(';');

        if ($.inArray('cookie_accept=1', dCookie) === -1) {
          $cookiePrivacy.fadeIn('slow');

          $cookiePrivacyClose.on('click', function(event) {
            event.preventDefault();
            $cookiePrivacy.fadeOut('fast');
          });

          $cookiePrivacyAccept.on('click', function(event) {
            event.preventDefault();

            var expiryDate = new Date();
                expiryDate.setMonth(expiryDate.getMonth() + 1);

            document.cookie = 'cookie_accept=1; expires=' + expiryDate.toGMTString();

            $cookiePrivacy.fadeOut('fast');
          });
        }
      }

      cookie();
    },

    winLoad: function() {

    },

    docReady_winLoad: function() {

    },

    resize: function() {

      /** Default **/
      fx_base.mainContainer(); // base.js
      fx_messages.make(); // base.js
      /* * * * * * */
    }

  }

/* * * * * * * * * * * * * * * * * */

/* * * * * * * * * * * * * * * * * */
/* Do not change or add nothing!!! */
/* * * * * * * * * * * * * * * * * */

  $(document).ready(function() {
    initialize.docReady();
    initialize.docReady_winLoad();
    initialize.resize();
    $(window).resize(function() {
      initialize.resize();
    });
  });
  $(window).load(function() {
    initialize.winLoad();
    initialize.docReady_winLoad();
    initialize.resize();
    $(window).resize(function() {
      initialize.resize();
    });
  });

/* * * * * * * * * * * * * * * * * */
